import Framework7 from 'framework7/framework7-lite.esm.bundle.js';

import LTdesktop from '../layouts/Desktop.vue';
import LTios from '../layouts/IOS.vue';
import LTandroid from '../layouts/Android.vue';

let Lt = LTdesktop; // set default theme for desktop
let ltName = 'md'; // ltname - F7 theme name. 'md' is default F7 theme used by android layout
if (Framework7.device.ios) {
  Lt = LTios; ltName = 'ios';
} else if (Framework7.device.android) {
  Lt = LTandroid;
}


var routes = [
  {
    path: '/',
    name: ltName,
    component: Lt,
  },
];

export default routes;
