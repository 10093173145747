<template>
  <f7-page class="page" name="home" :page-content="false" @page:init="onPageInit" style="background-color: #F9F9FA;">
    <f7-navbar class="navbar scroll-bottom" style="display: none;">
      <div class="navbar-inner">

        <div class="title" style="left:calc(50% - 18px);">

          <img src="/static/icons/main-icon.webp" class="scroll-bottom" style="width: 28px;">
        </div>

        <div class="right">
          <div class="scroll-bottom">
            <button v-on:click="download" class="button button-fill" style="width: 95px;height: 28px;font-size: 12px; font-weight: 700;border-radius: 100px;">ЗАГРУЗИТЬ</button>
          </div>
        </div>
      </div>
    </f7-navbar>

    <f7-page-content>

      <img src="/static/tf.png" style="position:absolute; top: -9000px;">
      <img src="/static/c1.png" style="position:absolute; top: -9000px;">
      <img src="/static/c2.svg" style="position:absolute; top: -9000px;">
      <img src="/static/c3.png" style="position:absolute; top: -9000px;">

      <div style="display: flex; margin: 0 16px 4px; border-bottom: 1px solid #DBDBDC;">
        <div style="padding: 16px; padding-left: 0">
          <img src="/static/icons/main-icon.webp" style="width: 118px;height: 118px;" @load="imageLoad" class="loading-icon">
        </div>

        <div style="padding: 16px; display: flex; flex-direction: column; justify-content: space-between;">
          <div>
            <div style="font-size: 22px;color: #000000;line-height: 26px; font-weight: 500;">Olimp - ставки на спорт</div>
            <div style="padding-top: 4px;font-size: 13px;color: #8C8C8C;line-height: 16px;">Ваша букмекерская контора</div>
          </div>
          <div style="margin-top: 18px;">
            <button v-on:click="download" class="button button-fill" style="width: 110px;height: 28px;font-size: 15px; font-weight: 700;border-radius: 100px;">ЗАГРУЗИТЬ</button>
          </div>
        </div>

      </div>

      <div style="display: flex; margin: 16px; padding-bottom: 5px; justify-content: space-between;">
        <div>
          <div style="font-weight: bold;font-size: 22px;color: #8C8C8C;">4,8 <img src="/static/rating.svg"></div>
          <div style="font-weght: 900;font-size: 12px;color: #C8C7CC;">13,3 тыс. оценок</div>
        </div>
        <div>
          <div style="font-weight: bold;font-size: 22px;color: #8C8C8C; text-align:center;">
            <span style="font-weight: bold;font-size: 15px;position: relative; top: -5px;">No</span>1</div>
          <div style="font-weght: 900;font-size: 12px;color: #C8C7CC; text-align:center;">Спорт</div>
        </div>
        <div>
          <div style="font-weight: bold;font-size: 22px;color: #8C8C8C; text-align:center;">17+</div>
          <div style="font-weght: 900;font-size: 12px;color: #C8C7CC; text-align:center;">Возраст</div>
        </div>
      </div>

      <div class="block" style="margin-top: 8px;margin-bottom: 14px;">
        <div class="swiper-container swiper-container1 swiper-init demo-swiper" data-space-between="10" data-slides-per-view="auto">
          <div class="swiper-wrapper">

            <div class="swiper-slide" style="width: 220px;" v-for="slider in sliders" :key="slider">

              <img :src="slider" style="width: 220px; height: 476px !important;" class="loading-slider" @load="imageLoad">
            </div>

          </div>
        </div>
      </div>

      <div style="margin: 0 16px; margin-top: 0px;padding-bottom: 10px; border-bottom: 1px solid #DBDBDC;">
        <i class="icon-phone" style="margin-right: 6px;"></i> <span style="position: relative;top: -3px;font-size: 12px;color: #000000;opacity: 0.5;font-weight: 700;">iPhone</span>
      </div>

      <div style="margin: 0 16px; margin-top: 9px;">
        <div style="color: #000000;font-size: 14px;">
          Olimp - ставки на спорт, это топовые события в мире спорта в одном месте!

          <a href="#" @click="showMore" style="font-size: 15px;color: #007AFF;">...еще</a>

          <div id="info-more" style="display: none;">
            <br>
            БУДЬ В КУРСЕ<br>
            Получай уведомления о начале матчей или новых кэфах<br><br>
            ЛЕГКИЙ ДОСТУП<br>
            Сканируй свою клубную карту и начинай делать ставки из своего аккаунта<br><br>
            СПЕЦИАЛЬНЫЕ ПРЕДЛОЖЕНИЯ<br>
            Получи доступ к эксклюзивным предложеним и акциям только для держателей клубных карт<br><br>
            Присоединяйся к сообществу OLIMP и болей вместе с нами!
          </div>
        </div>
      </div>

      <div style="margin: 0 16px; margin-top: 9px;">
        <div class="row">
          <div class="col-70" style="color: #000000;font-size: 20px;font-weight: bold;">
            Оценки и отзывы
          </div>
          <div class="col-30 text-align-right" style="position: relative; top: 2px;font-size: 15px; color: #007AFF;">

          </div>
        </div>

        <div class="row">
          <div class="col-30">
            <div style="color:#4B4A50; font-size: 54px; font-weight: bold;text-align: center;">4,8</div>
            <div style="color:#4B4A50; font-size: 15px; font-weight: bold; text-align: center; position: relative; top: -3px;">из 5</div>
          </div>
          <div class="col-70" style="padding-top: 10px;">
            <div class="row" style="height: 10px;">
              <div class="col-30 text-align-right" style="letter-spacing: -5px;">
                <i class="icon-star"></i>
                <i class="icon-star"></i>
                <i class="icon-star"></i>
                <i class="icon-star"></i>
                <i class="icon-star"></i>
              </div>
              <div class="col-70">
                <div style="position:relative; top: 10px;width: 100%;height: 2.5px;background: #EFEFF4; border-radius: 1px;">
                  <div style="background: #85848A; width: 60%;height: 2.5px;border-radius: 1px;"></div>
                </div>
              </div>
            </div>

            <div class="row" style="height: 10px;">
              <div class="col-30 text-align-right" style="letter-spacing: -5px;">
                <i class="icon-star"></i>
                <i class="icon-star"></i>
                <i class="icon-star"></i>
                <i class="icon-star"></i>
              </div>
              <div class="col-70">
                <div style="position:relative; top: 10px;width: 100%;height: 2.5px;background: #EFEFF4; border-radius: 1px;">
                  <div style="background: #85848A; width: 10%;height: 2.5px;border-radius: 1px;"></div>
                </div>
              </div>
            </div>

            <div class="row" style="height: 10px;">
              <div class="col-30 text-align-right" style="letter-spacing: -5px;">
                <i class="icon-star"></i>
                <i class="icon-star"></i>
                <i class="icon-star"></i>

              </div>
              <div class="col-70">
                <div style="position:relative; top: 10px;width: 100%;height: 2.5px;background: #EFEFF4; border-radius: 1px;">
                  <div style="background: #85848A; width: 10%;height: 2.5px;border-radius: 1px;"></div>
                </div>
              </div>
            </div>

            <div class="row" style="height: 10px;">
              <div class="col-30 text-align-right" style="letter-spacing: -5px;">
                <i class="icon-star"></i>
                <i class="icon-star"></i>

              </div>
              <div class="col-70">
                <div style="position:relative; top: 10px;width: 100%;height: 2.5px;background: #EFEFF4; border-radius: 1px;">
                  <div style="background: #85848A; width: 0%;height: 2.5px;border-radius: 1px;"></div>
                </div>
              </div>
            </div>

            <div class="row" style="height: 10px;">
              <div class="col-30 text-align-right" style="letter-spacing: -5px;">
                <i class="icon-star" style="margin-top: 5px;"></i>
              </div>
              <div class="col-70">
                <div style="position:relative; top: 10px;width: 100%;height: 2.5px;background: #EFEFF4; border-radius: 1px;">
                  <div style="background: #85848A; width: 0%;height: 2.5px;border-radius: 1px;"></div>
                </div>
              </div>
            </div>

            <div class="text-align-right" style="margin-top: 10px;color: #8A8A8F;font-size: 15px;">
              13 798 оценка
            </div>

          </div>
        </div>

      </div>

      <div class="block" style="margin-top: 8px;margin-bottom: 14px;">
        <div class="swiper-container swiper-container2 swiper-init demo-swiper" data-space-between="10">
          <div class="swiper-wrapper">

            <div class="swiper-slide" v-for="fd in feedbacks">
              <div style="background: #F0F0F8;border-radius: 8px; padding: 10px 20px 58px 20px;">
                <div class="row">
                  <div class="col-50" style="font-size: 15px;color: #000000; font-weight: 700;">{{fd.title}}</div>
                  <div class="col-50" style="text-align:right;font-size: 15px;color: #8A8A8F;">{{fd.date}}</div>
                </div>
                <div class="row" >
                  <div class="col-50" ><i class="icon-stars"></i> </div>
                  <div class="col-50" style="text-align:right;font-size: 15px;color: #8A8A8F;">{{fd.author}}</div>
                </div>

                <div style="font-size: 14px;color: #000000; margin-top: 8px;">
                  {{fd.text}}
                </div>

              </div>
            </div>

          </div>
        </div>
      </div>

      <div style="margin: 0 16px; margin-top: 9px;padding-bottom: 5px;">

        <div class="row" style="margin-top: 10px;">
          <div class="col-50" style="font-size: 15px; color: #007AFF;">
            <i class="icon-feedback" style="position: relative; top: 3px;margin-right: 5px;"></i>
            <a href="mailto:olimpapp@gmail.com" class="external">Написать отзыв</a>
          </div>
          <div class="col-50" style="text-align: right; font-size: 15px; color: #007AFF;">
            <i class="icon-support" style="position: relative; top: 1px;margin-right: 5px;"></i><span style="position: relative; top: -4px;">Поддержка ПО</span>
          </div>
        </div>

      </div>

      <div style="margin: 0 16px; margin-top: 9px;padding-bottom: 5px;">

        <div style="color: #000000;font-size: 20px;font-weight: bold;">
          Информация
        </div>

      </div>

      <div style="margin: 0 16px; margin-top: 9px;padding-bottom: 5px; border-bottom: 1px solid #DBDBDC;" v-for="inf in info">
        <div class="row" style="padding: 4px;">
          <div class="col-50" style="color: #8C8C8C;font-size: 14px;">
            {{inf.title}}
          </div>
          <div class="col-50" style="text-align: right;color: #000000;font-size: 14px;">
            {{inf.value}}
          </div>
        </div>
      </div>

      <br><br>

    </f7-page-content>
  </f7-page>
</template>

<style>
  .scroll-bottom {
    display: none;
  }
  .page-content {
    padding-top: 0;
  }
  .swiper-container1 {
    height: 476px !important;
  }
  .swiper-container2 .swiper-slide, .swiper-container2 .swiper-wrapper {
    max-height: 200px !important;
  }
  .swiper-container1 .swiper-slide, .swiper-container2 .swiper-slide {
    background: transparent !important;
  }
</style>

<script>

  import '../css/icons.css';
  import '../css/app-ios.less';


  export default {
    data : function () {
      return {
        imageLoadCounter : 1,
        link : '',
        step : 'enter.sms',
        download_status : 'button',
        sms_timer_value : 60,
        sms_timer_instance : null,
        sliders : [
          './static/screens/app/ios-1.png',
          './static/screens/app/ios-2.png',
          './static/screens/app/ios-3.png',
          './static/screens/app/ios-4.png',
          './static/screens/app/ios-5.png',
        ],
        info : [
          {title : 'Провайдер', value: 'BK OLIMP, OOO'},
          {title : 'Размер', value: '85,6 МБ'},
          {title : 'Категория', value: 'Спорт'},
          {title : 'Совместимость', value: 'Да (этот iPhone)'},
          {title : 'Языки', value: 'русский'},
        ],
        feedbacks : [
          {title : 'Очень круто', date : '21 авг.', author : 'antonsd', text : 'Последнее время делаю ставки только здесь. Это здорово, что есть такое удобное приложение. А главное не лагает и деньги выводятся без проблем.'},
          {title : 'Отзыв о БК', date : '30 авг.', author : 'Yanchik2015', text : 'Хорошее БК, один из самых честных букмекров, которых я встречала. Очень достойные коэффициенты. Рекомендую!'},
          {title : 'Рекомендую', date : '6 сент.', author : 'grishaZzZ', text : 'Как по мне, так контора топ. Поставил – выиграл – вывел и никаких проблем! Особенно радуют уведомления о выигрыше)) В общем доволен, всем советую.'},
        ]
      }
    },
    methods : {
      showMore : function (e) {
        $(e.target).hide();
        $('#info-more').show();
      },
      imageLoad : function (e) {
        //$(e.target).parent().children('.preloader').remove();
      },
      enter : function () {
        //
      },
      download : function () {
        window.location.href = this.$root.ios_link;
      },

      onPageInit : function () {
        //console.log('page-init');
        $(document).find('.page-content').on('scroll', function () {
          let scrollTop = this.scrollTop;
          if (scrollTop > 130) {
            $('.scroll-bottom').show();
          } else {
            $('.scroll-bottom').hide();
          }

        });


      }
    },
    on: {
      pageMounted : function () {
        //console.log('mounted');
      },

    }
  }



</script>

