<template>
  <f7-app :params="f7params">
    <f7-view main class="safe-areas" url="/"></f7-view>
  </f7-app>
</template>
<script>

  import routes from '../js/routes.js';
  //import platformMixin from '../js/mixins.js';


  export default {

    data() {
      return {
        // Framework7 Parameters
        f7params: {
          name: 'playmarket-page', // App name
          theme: routes[0].name, // Automatic theme detection

          // App routes
          routes: routes,
          touch: {
            // Enable fast clicks
            fastClicks: true,
          },
        },
        // Login screen data
        username: '',
        password: '',

      }
    },
    //mixins: [platformMixin],
    methods: {
      alertLoginData() {
        this.$f7.dialog.alert('Username: ' + this.username + '<br>Password: ' + this.password, () => {
          this.$f7.loginScreen.close();
        });
      },

    },
    mounted() {
      this.$f7ready((f7) => {

        // Call F7 APIs here

      });
    }
  }
</script>
